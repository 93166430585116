import React, { useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useGetCourseLecture, useGetCourseModule } from "../../../utils/firebaseDataFetch/useGetCourses";
import Lectures from "./Lectures";
import CreateLecture from "./CreateLecture";
import UpdateModuleModal from "../UpdateModuleModal";
import { firebaseDeleteAny } from "../../../utils/firebaseDataFetch/uploadCourse";
import DeleteModal from "./DeleteModal";


const ModuleTable = ({ courseId }) => {
  useGetCourseModule();
  useGetCourseLecture()

  const [activeIndex,setActiveIndex]=useState(false)
  const [handleCreateLectureModal,setHandleCreateLectureModal]=useState(false)
  const [handleUpdateModule,setHandleUpdateModule]=useState(false)

  const [AddLectureProps,setAddLectureProps]=useState(null)
  const [moduleProps,setModuleProps]=useState(null)

    const [isDeleteModuleModal, setIsDeleteModuleModal] = useState(false);
    const [selectedCourse,setSelectedCourse]=useState(null)
    const [deleteDocId,setDeleteDocId]=useState(null)
  

  

  

  const modulesBeforeFilter = useSelector((state) => state.course?.modules);
  const modules = modulesBeforeFilter.filter(
    (module) => module?.courseId == courseId
  );

// console.log(modules)
  const handleEditCourse = (module) => {
    setModuleProps(module)
    setHandleUpdateModule(true)

  };

  
  const handleAddLecture = (id, courseId) => {
    const lectureProps= {id:id,courseId:courseId}
   
    
    setAddLectureProps(lectureProps)
    setHandleCreateLectureModal(!handleAddLecture)
    // navigate("addModule", { state: { id, courseId, title } });
  };

  const confirmDelete = (id) => {
  
    firebaseDeleteAny("module",id)
    setIsDeleteModuleModal(false)

    
  };

  const handleDeleteModule = (id,tittle) => {
    // console.log(id)

    setDeleteDocId(id)
    setSelectedCourse(tittle)
    setIsDeleteModuleModal(true);
    
  };


  const handleToggle=(index)=>{
    setActiveIndex(activeIndex=== index ? null : index)

  }

  // console.log(modules)

  return (
    <>

{isDeleteModuleModal && <DeleteModal 
      isOpen={isDeleteModuleModal}
      onClose={()=>setIsDeleteModuleModal(false)}
      onConfirm={()=>confirmDelete(deleteDocId)}
      courseName={selectedCourse}
      
      />}

    {handleUpdateModule  && <UpdateModuleModal setHandleUpdateModule={setHandleUpdateModule} setModuleProps={setModuleProps} props={moduleProps} />}
   
    {handleCreateLectureModal || AddLectureProps && <CreateLecture setAddLectureProps={setAddLectureProps} setHandleCreateLectureModal={setHandleCreateLectureModal} props={AddLectureProps} />}
    <div className="px-6 md:px-24">
      <motion.div
        className="overflow-x-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        <div className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <div>
            {modules.map((module,index) => (
             
                <motion.div
                  key={module?.id}
                  className="border-b hover:bg-gray-50  flex flex-wrap s items-center"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="p-3 md:w-2/3">{module?.module_title}</div>

                  <div className="p-3 flex justify-center space-x-2 md:space-x-7">
                    <motion.button
                      onClick={() => handleAddLecture(module?.id, module?.courseId)}
                      className="text-white hover:text-gray-100 px-2 py-1  rounded-lg bg-green-700 flex flex-wrap items-center justify-between gap-x-1"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <DocumentTextIcon className="w-5 h-5" />
                      Add Lecture
                    </motion.button>

                    <motion.button
                      onClick={() => handleEditCourse(module)}
                      className="text-blue-500 hover:text-blue-700"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <PencilIcon className="w-5 h-5 " />
                    </motion.button>
                    <motion.button
                      onClick={() => handleDeleteModule(module?.id, module?.module_title)}
                      className="text-red-500 hover:text-red-700"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </motion.button>
                  </div>
                  <div className=""
                  onClick={()=>handleToggle(index)}>
                    <i class={`fa-solid p-3 ${activeIndex===index ? `fa-chevron-up` : `fa-chevron-down`}`}/>
                  </div>
                  {activeIndex === index && (<div className="w-full px-10">
                    <Lectures doc_id={module?.doc_id} courseId={courseId}/></div>) }
                  
                </motion.div>
            
            ))}
          </div>
        </div>
      </motion.div>
    </div>
    </>
  );
};

export default ModuleTable;
