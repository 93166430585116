import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChartBarIcon,
  CurrencyRupeeIcon,
  BookOpenIcon,
  PlusIcon,
  UsersIcon,
  ClockIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CreateCourse from "../admin/CreateCourse";
import { useLocation } from "react-router-dom";
import AdminHeader from "../admin/AdminHeader";
import AdminSidebar from "./components/AdminSidebar";
import Overview from './components/Overview';

const AdminDashboard = () => {
  const location = useLocation();
  const { course } = location?.state || false;

  // Get the active section from localStorage or default to "overview"
  const [activeSection, setActiveSection] = useState(() => {
    return localStorage.getItem('adminActiveSection') || "overview";
  });
  const [handleSideBar, setHandleSideBar] = useState(true);
  
  const dashboardSections = [
    {
      id: "overview",
      icon: <ChartBarIcon className="w-6 h-6 text-violet-600" />,
      label: "Overview",
    },
    {
      id: "courses",
      icon: <BookOpenIcon className="w-6 h-6 text-violet-600" />,
      label: "Courses",
    },
    {
      id: "create-course",
      icon: <PlusIcon className="w-6 h-6 text-violet-600" />,
      label: "Create Course",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="bg-white border-b border-gray-200 fixed top-0 left-0 right-0 z-40">
        <div className=" mx-auto px-4 sm:px-6 lg:px-24">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img 
                src="/logo.svg" 
                alt="Digital Do Logo" 
                className="h-8 w-auto"
              />
              <div className="ml-3 text-xl font-semibold text-gray-800">
                Admin Dashboard
              </div>
            </div>
            <AdminHeader />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-20 pb-24 md:pb-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <AnimatePresence mode="wait">
            {activeSection === "overview" && (
              <motion.div
                key="overview"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <Overview />
              </motion.div>
            )}
            {activeSection === "create-course" && (
              <motion.div
                key="create-course"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                <CreateCourse />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </main>

      <AdminSidebar 
        activeSection={activeSection} 
        setActiveSection={setActiveSection}
      />
    </div>
  );
};

export default AdminDashboard;
