import React from 'react';
import { motion } from 'framer-motion';
import { BarChart, Bar, AreaChart, Area } from 'recharts';
import { Card, Title, DonutChart } from "@tremor/react";
import {
  CurrencyRupeeIcon,
  BookOpenIcon,
  UsersIcon,
  ClockIcon,
  ArrowTrendingUpIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";
import { 
  Rocket, 
  Users2, 
  GraduationCap,
  Target,
  TrendingUp 
} from 'lucide-react';

// Mock Data with extended information
const MOCK_DASHBOARD_DATA = {
  totalCoursesSold: 245,
  totalRevenue: 487500,
  activeStudents: 1245,
  growth: {
    revenue: 23,
    students: 15,
    courses: 8
  },
  courseCategories: [
    { name: "Development", value: 35 },
    { name: "Design", value: 25 },
    { name: "Business", value: 20 },
    { name: "Marketing", value: 15 },
    { name: "Other", value: 5 },
  ],
  recentCourses: [
    {
      id: 1,
      name: "Web Development Bootcamp",
      students: 75,
      revenue: 112500,
      progress: 65,
    },
    {
      id: 2,
      name: "UI/UX Design Masterclass",
      students: 55,
      revenue: 82500,
      progress: 45,
    },
    {
      id: 3,
      name: "Python Programming",
      students: 65,
      revenue: 97500,
      progress: 55,
    },
  ],
  revenueHistory: [
    { month: "Jan", revenue: 35000 },
    { month: "Feb", revenue: 42000 },
    { month: "Mar", revenue: 55000 },
    { month: "Apr", revenue: 65000 },
    { month: "May", revenue: 78000 },
  ],
  studentActivity: [
    { day: "Mon", active: 850 },
    { day: "Tue", active: 920 },
    { day: "Wed", active: 880 },
    { day: "Thu", active: 950 },
    { day: "Fri", active: 930 },
    { day: "Sat", active: 750 },
    { day: "Sun", active: 700 },
  ]
};

const Overview = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    }
  };

  const StatCard = ({ title, value, icon: Icon, color, growth }) => (
    <motion.div
      variants={itemVariants}
      whileHover={{ scale: 1.02 }}
      className={`bg-white p-6 rounded-xl shadow-md border border-gray-100`}
    >
      <div className="flex items-center justify-between">
        <div className={`p-3 rounded-lg ${color}`}>
          <Icon className="w-6 h-6 text-white" />
        </div>
        {growth && (
          <div className="flex items-center space-x-1 text-green-600">
            <ArrowUpIcon className="w-4 h-4" />
            <span className="text-sm font-medium">+{growth}%</span>
          </div>
        )}
      </div>
      <div className="mt-4">
        <h3 className="text-gray-500 text-sm">{title}</h3>
        <p className="text-2xl font-bold text-gray-800 mt-1">{value}</p>
      </div>
    </motion.div>
  );

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-6"
    >
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Revenue"
          value={`₹${MOCK_DASHBOARD_DATA.totalRevenue.toLocaleString()}`}
          icon={CurrencyRupeeIcon}
          color="bg-violet-500"
          growth={MOCK_DASHBOARD_DATA.growth.revenue}
        />
        <StatCard
          title="Active Students"
          value={MOCK_DASHBOARD_DATA.activeStudents}
          icon={Users2}
          color="bg-blue-500"
          growth={MOCK_DASHBOARD_DATA.growth.students}
        />
        <StatCard
          title="Courses Sold"
          value={MOCK_DASHBOARD_DATA.totalCoursesSold}
          icon={GraduationCap}
          color="bg-green-500"
          growth={MOCK_DASHBOARD_DATA.growth.courses}
        />
        <StatCard
          title="Completion Rate"
          value="78%"
          icon={Target}
          color="bg-orange-500"
        />
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Revenue Trend */}
        <motion.div
          variants={itemVariants}
          className="bg-white p-6 rounded-xl shadow-md border border-gray-100"
        >
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-2">
              <TrendingUp className="w-5 h-5 text-violet-500" />
              <h3 className="text-lg font-semibold text-gray-800">Revenue Trend</h3>
            </div>
            <select className="text-sm border rounded-md px-2 py-1">
              <option>Last 7 days</option>
              <option>Last 30 days</option>
              <option>Last 90 days</option>
            </select>
          </div>
          <div className="h-[300px]">
            <AreaChart
              width={500}
              height={300}
              data={MOCK_DASHBOARD_DATA.revenueHistory}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="revenue"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorRevenue)"
              />
            </AreaChart>
          </div>
        </motion.div>

        {/* Course Categories */}
        <motion.div
          variants={itemVariants}
          className="bg-white p-6 rounded-xl shadow-md border border-gray-100"
        >
          <div className="flex items-center space-x-2 mb-6">
            <BookOpenIcon className="w-5 h-5 text-violet-500" />
            <h3 className="text-lg font-semibold text-gray-800">Course Categories</h3>
          </div>
          <DonutChart
            data={MOCK_DASHBOARD_DATA.courseCategories}
            category="value"
            index="name"
            valueFormatter={(number) => `${number}%`}
            colors={["violet", "blue", "green", "yellow", "rose"]}
          />
        </motion.div>
      </div>

      {/* Recent Activity */}
      <motion.div
        variants={itemVariants}
        className="bg-white p-6 rounded-xl shadow-md border border-gray-100"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-2">
            <ClockIcon className="w-5 h-5 text-violet-500" />
            <h3 className="text-lg font-semibold text-gray-800">Recent Activity</h3>
          </div>
          <button className="text-violet-600 hover:text-violet-700 text-sm font-medium">
            View All
          </button>
        </div>
        <div className="space-y-4">
          {MOCK_DASHBOARD_DATA.recentCourses.map((course) => (
            <motion.div
              key={course.id}
              whileHover={{ scale: 1.01 }}
              className="flex items-center justify-between p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-violet-100 rounded-lg">
                  <Rocket className="w-5 h-5 text-violet-600" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-800">{course.name}</h4>
                  <p className="text-sm text-gray-500">{course.students} Students Enrolled</p>
                </div>
              </div>
              <div className="text-right">
                <p className="font-semibold text-green-600">
                  ₹{course.revenue.toLocaleString()}
                </p>
                <div className="flex items-center space-x-1 text-sm text-gray-500">
                  <span>{course.progress}% Complete</span>
                  <div className="w-20 h-1.5 bg-gray-200 rounded-full">
                    <div
                      className="h-full bg-violet-500 rounded-full"
                      style={{ width: `${course.progress}%` }}
                    />
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Overview; 