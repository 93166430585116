import React from 'react';
import { motion } from 'framer-motion';
import { 
  CalendarIcon, 
  ClockIcon, 
  ArrowRightIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';

const ComingSoon = () => {
  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    initial: { y: 20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20
      }
    }
  };

  const sparkleVariants = {
    animate: {
      scale: [1, 1.2, 1],
      rotate: [0, 5, -5, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={containerVariants}
      className="fixed inset-0 z-50 flex items-center justify-center bg-gradient-to-br from-violet-900 to-indigo-900 backdrop-blur-sm"
    >
      <div className="w-full max-w-4xl px-6 py-12 md:px-8">
        <motion.div 
          className="text-center space-y-8"
          variants={containerVariants}
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="space-y-2">
            <motion.div 
              variants={sparkleVariants}
              animate="animate"
              className="flex justify-center mb-6"
            >
              <SparklesIcon className="w-16 h-16 text-violet-400" />
            </motion.div>
            <h1 className="text-4xl md:text-6xl font-bold text-white tracking-tight">
              Something Big is Coming
            </h1>
            <p className="text-lg md:text-xl text-violet-200">
              We're crafting something amazing for you
            </p>
          </motion.div>

          {/* Launch Info */}
          <motion.div 
            variants={itemVariants}
            className="flex flex-col md:flex-row items-center justify-center gap-6 text-violet-100"
          >
            <div className="flex items-center space-x-2">
              <CalendarIcon className="w-6 h-6" />
              <span>Launching in April 2025</span>
            </div>
            <div className="hidden md:block w-1.5 h-1.5 bg-violet-400 rounded-full"></div>
            <div className="flex items-center space-x-2">
              <ClockIcon className="w-6 h-6" />
              <span>Stay tuned for updates</span>
            </div>
          </motion.div>

          {/* Social Link */}
          <motion.div variants={itemVariants}>
            <a 
              href="https://www.instagram.com/digitaldolearning/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-violet-600 hover:bg-violet-700 text-white rounded-full transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl group"
            >
              <span>Follow us on Instagram</span>
              <ArrowRightIcon className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
            </a>
          </motion.div>

          {/* Features Preview */}
          <motion.div 
            variants={itemVariants}
            className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12"
          >
            {[
              "Expert-Led Courses",
              "Interactive Learning",
              "Industry-Ready Skills"
            ].map((feature, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                className="bg-white/10 backdrop-blur-sm rounded-lg p-4 text-violet-100"
              >
                {feature}
              </motion.div>
            ))}
          </motion.div>

          {/* Newsletter Signup */}
          <motion.div 
            variants={itemVariants}
            className="mt-12 text-center text-violet-200 text-sm"
          >
            <p>Get ready to transform your learning journey</p>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ComingSoon; 