import React from 'react';
import { motion } from 'framer-motion';
import {
  ChartBarIcon,
  BookOpenIcon,
  PlusIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';

const sidebarLinks = [
  {
    id: "overview",
    icon: ChartBarIcon,
    label: "Overview",
  },
  {
    id: "courses",
    icon: BookOpenIcon,
    label: "Courses",
  },
  {
    id: "create-course",
    icon: PlusIcon,
    label: "Create Course",
  },
  {
    id: "modules",
    icon: UsersIcon,
    label: "Add Module",
  }
];

const AdminSidebar = ({ activeSection, setActiveSection }) => {
  return (
    <>
      {/* Desktop Floating Navigation */}
      <motion.div 
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed bottom-6 left-[35%] transform -translate-x-1/2 z-50 hidden md:block"
      >
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-lg border border-gray-200 p-2 px-3">
          <div className="flex items-center space-x-2">
            {sidebarLinks.map((section) => (
              <motion.button
                key={section.id}
                onClick={() => {
                  setActiveSection(section.id);
                  localStorage.setItem('adminActiveSection', section.id);
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`
                  flex flex-col items-center justify-center p-3 rounded-xl
                  min-w-[5.5rem]
                  ${
                    activeSection === section.id
                      ? 'bg-violet-100 text-violet-600'
                      : 'text-gray-500 hover:bg-gray-50'
                  }
                `}
              >
                <section.icon className={`w-6 h-6 ${
                  activeSection === section.id ? 'text-violet-600' : 'text-gray-400'
                }`} />
                <span className="text-sm mt-1 font-medium">
                  {section.label}
                </span>
              </motion.button>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Mobile Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 md:hidden z-30">
        <div className="flex justify-around items-center h-16 px-4">
          {sidebarLinks.map((section) => (
            <motion.button
              key={section.id}
              onClick={() => {
                setActiveSection(section.id);
                localStorage.setItem('adminActiveSection', section.id);
              }}
              whileTap={{ scale: 0.95 }}
              className={`flex flex-col items-center justify-center py-1 px-3 ${
                activeSection === section.id 
                  ? 'text-violet-600' 
                  : 'text-gray-400'
              }`}
            >
              <section.icon className="w-6 h-6" />
              <span className="text-xs mt-1">{section.label}</span>
            </motion.button>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminSidebar; 