import React, { useState, useMemo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  Book,
  FileText,
  MessageCircle,
  Award,
  ChevronRight,
  ChevronDown,
  CheckCircle2,
  ArrowLeft,
  ArrowRight,
  Users,
  Play,
  XCircle,
  ChevronLeft,
} from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useCourseProgress from "./utils/useCourseProgress";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useGetFullCourseStructure from "./utils/useGetFullCourseStructure";
import {
  useGetCourseLecture,
  useGetCourseModule,
} from "../utils/firebaseDataFetch/useGetCourses";
import { firebaseAuth, firebaseDb } from "../utils/firebase";
import { useSelector } from "react-redux";
import CoursePageSkeleton from '../utils/components/CoursePageSkeleton';

// Updated Mock Data with status and expanded property for modules
const testMockData = [
  {
    module_title: "Basic Tools",
    order: "1",
    isExpanded: true,
    icon: <Book className="w-5 h-5 text-violet-600" />,
    lectures: [
      {
        order: "1",
        videoLink: "https://ssdsds.com",
        lecture_title: "Introduction Basics",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
      {
        order: "2",
        videoLink: "https://ssdsds.com",
        lecture_title: "Basic Tools",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
    ],
  },
  {
    module_title: "Introduction",
    order: "2",
    isExpanded: true,
    icon: <Users className="w-5 h-5 text-violet-600" />,
    lectures: [
      {
        order: "1",
        videoLink: "https://ssdsds.com",
        lecture_title: "Introduction Basics",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
      {
        order: "2",
        videoLink: "https://ssdsds.com",
        lecture_title: "Basic Tools",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
    ],
  },
];

// Custom hook for calculating course progress

const CoursePage = () => {
  const navigate = useNavigate();
  const { courseId, courseTitle } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  
  // Get user from Redux store
  const userData = useSelector(state => state.users?.user);

  // Initialize with empty structure
  const [courseStructure, setCourseStructure] = useState([]);
  
  // Move hooks to top but initialize with safe defaults
  useGetCourseModule();
  useGetCourseLecture();
  const fetchedCourseStructure = useGetFullCourseStructure(courseId);
  
  const [mockData, setMockData] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const courseProgress = useCourseProgress(mockData);
  const [activeSection, setActiveSection] = useState("overview");
  const [isRightSectionCollapsed, setIsRightSectionCollapsed] = useState(false);
  const [completionPopup, setCompletionPopup] = useState(null);

  // Handle course structure updates
  useEffect(() => {
    if (fetchedCourseStructure && fetchedCourseStructure.length > 0) {
      // Set isExpanded true for first module, false for others
      const initializedStructure = fetchedCourseStructure.map((module, index) => ({
        ...module,
        isExpanded: index === 0 // true for first module, false for others
      }));

      setCourseStructure(initializedStructure);
      setMockData(initializedStructure);
      setSelectedModule(initializedStructure[0]);
      setSelectedLecture(initializedStructure[0]?.lectures[0]);
    }
  }, [fetchedCourseStructure]);

  console.log(mockData)

  // Auth and access check
  useEffect(() => {
    let isMounted = true; // For cleanup

    const checkAuthAndAccess = async () => {
      try {
        // Check stored auth first
        const storedUser = localStorage.getItem("getUserData");
        let userId = null;

        if (storedUser) {
          const parsedUser = JSON.parse(storedUser);
          userId = parsedUser.userId;
        } else if (firebaseAuth.currentUser) {
          userId = firebaseAuth.currentUser.uid;
        }

        if (!userId) {
          const mainDomain = process.env.NODE_ENV === "production"
            ? `https://${window.location.hostname.replace("learn.", "")}`
            : `http://${window.location.hostname.replace("learn.", "")}:3000`;

          window.location.href = `${mainDomain}/courseDetails/${courseId}`;
          return;
        }

        // Check course purchase
        const paymentsRef = collection(firebaseDb, "payments");
        const q = query(
          paymentsRef,
          where("userId", "==", userId),
          where("courseID", "==", courseId)
        );

        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          const mainDomain = process.env.NODE_ENV === "production"
            ? `https://${window.location.hostname.replace("learn.", "")}`
            : `http://${window.location.hostname.replace("learn.", "")}:3000`;

          window.location.href = `${mainDomain}/courseDetails/${courseId}`;
          return;
        }

        // If we reach here, user is authorized
        if (isMounted) {
          setIsAuthorized(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Access check failed:", error);
        if (isMounted) {
          setIsAuthorized(false);
          setIsLoading(false);
        }
      }
    };

    checkAuthAndAccess();

    return () => {
      isMounted = false;
    };
  }, [courseId]);

  // Component functions
  const toggleModuleExpansion = (moduleIndex) => {
    const updatedData = [...mockData];
    // Only allow toggle if it's not the first module
    if (moduleIndex !== 0) {
      updatedData[moduleIndex].isExpanded = !updatedData[moduleIndex].isExpanded;
      setMockData(updatedData);
    }
  };

  const handleLectureCompletion = (lecture) => {
    setCompletionPopup({
      lecture: lecture,
      message: "Awesome job! Are you sure you want to mark this chapter as complete?",
    });
  };

  const confirmLectureCompletion = () => {
    if (completionPopup) {
      const updatedMockData = mockData.map((module) => ({
        ...module,
        lectures: module.lectures.map((lecture) =>
          lecture === completionPopup.lecture
            ? { ...lecture, status: true }
            : lecture
        ),
      }));

      setMockData(updatedMockData);
      setSelectedLecture({ ...completionPopup.lecture, status: true });
      setCompletionPopup(null);
    }
  };

  const cancelLectureCompletion = () => {
    setCompletionPopup(null);
  };

  // Add this helper function near the top of your component
  const getEmbedUrl = (url) => {
    if (!url) return null;
    
    // YouTube URL conversion
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.includes('youtube.com') 
        ? url.split('v=')[1]
        : url.split('youtu.be/')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    
    // Vimeo URL conversion
    if (url.includes('vimeo.com')) {
      const videoId = url.split('vimeo.com/')[1];
      return `https://player.vimeo.com/video/${videoId}`;
    }
    
    // Return original URL if no conversion needed
    return url;
  };

  // Loading state
  if (isLoading) {
    return <CoursePageSkeleton />;
  }

  // Wait for both authorization and course structure
  if (!isAuthorized || !courseStructure || courseStructure.length === 0) {
    return <CoursePageSkeleton />;
  }

  // Sections for the bottom navigation
  const sections = [
    {
      id: "overview",
      title: "Overview",
      icon: <Book className="w-5 h-5" />,
    },
    {
      id: "discuss",
      title: "Discuss",
      icon: <MessageCircle className="w-5 h-5" />,
    },
    {
      id: "notes",
      title: "Notes",
      icon: <FileText className="w-5 h-5" />,
    },
    {
      id: "certificates",
      title: "Certificates",
      icon: <Award className="w-5 h-5" />,
    },
  ];

  // Render section content
  const renderSectionContent = () => {
    switch (activeSection) {
      case "overview":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Lecture Overview
            </h3>
            <p className="mt-2 text-gray-600">
              {selectedLecture?.lecture_title} provides a comprehensive
              introduction to the core concepts.
            </p>
          </div>
        );
      case "discuss":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Discussion Forum
            </h3>
            <p className="mt-2 text-gray-600">
              Connect with your peers, share insights, and learn together.
            </p>
            <button className="mt-4 flex items-center space-x-2 bg-violet-600 text-white px-4 py-2 rounded-lg hover:bg-violet-700">
              <Users className="w-5 h-5" />
              <span>Join Community</span>
            </button>
          </div>
        );
      case "notes":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Lecture Notes
            </h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedLecture.notes.map((note, index) => (
                <a
                  key={index}
                  href={note.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-3 py-1 border border-violet-500 text-violet-600 rounded-full hover:bg-violet-100 transition-colors flex items-center space-x-2"
                >
                  <FontAwesomeIcon icon={faDownload} />
                  <span>{note.title}</span>
                </a>
              ))}
            </div>
          </div>
        );
      case "certificates":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Course Certificates
            </h3>
            <p className="mt-2 text-gray-600">
              Complete all lectures to unlock your course certificate.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-wrap lg:flex-nowrap relative">
      {/* Left Section - 70% width */}
      <div className={`w-full ${isRightSectionCollapsed ? 'lg:w-full' : 'lg:w-[70%]'} p-4 lg:p-8`}>
        {/* Course Title */}
        <h1 className="text-2xl lg:text-3xl font-bold text-violet-900 mb-4">
          {courseTitle}
        </h1>

        {/* Video Player */}
        <div className="w-full aspect-video bg-black mb-4 rounded-lg overflow-hidden">
          {selectedLecture?.videoLink ? (
            <iframe
              src={getEmbedUrl(selectedLecture.videoLink)}
              title={selectedLecture.lecture_title}
              className="w-full h-full"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <Play className="w-16 h-16 text-white opacity-50" />
              <p className="text-white opacity-50 mt-2">Select a lecture to start</p>
            </div>
          )}
        </div>

        {/* Section Navigation */}
        <div className="flex space-x-2 overflow-x-scroll no-scrollbar  sm:overflow-auto lg:space-x-4 mb-4">
          {sections.map((section) => (
            <motion.button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`
                flex items-center space-x-2 px-3 py-2 rounded-full transition-all
                ${
                  activeSection === section.id
                    ? "bg-violet-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-violet-100"
                }
              `}
              whileTap={{ scale: 0.95 }}
            >
              {section.icon}
              <span className="text-sm font-medium">{section.title}</span>
            </motion.button>
          ))}
        </div>

        {/* Section Content */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
          >
            {renderSectionContent()}
          </motion.div>
        </AnimatePresence>

        {/* Feedback Button */}
        <motion.button
          className="w-full mt-4 bg-violet-600 text-white py-3 rounded-lg hover:bg-violet-700 transition-colors"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Provide Lecture Feedback
        </motion.button>
      </div>

      {/* Toggle Button - Fixed Position at Top Right, Hidden on Mobile */}
      <button
        onClick={() => setIsRightSectionCollapsed(!isRightSectionCollapsed)}
        className="hidden lg:block fixed top-4 right-0 z-50 bg-violet-600 text-white p-2 rounded-l-lg shadow-lg"
      >
        {isRightSectionCollapsed ? (
          <ChevronLeft className="w-5 h-5" />
        ) : (
          <ArrowRight className="w-5 h-5" />
        )}
      </button>

      {/* Right Section - 30% width */}
      {!isRightSectionCollapsed && (
        <div className="w-full lg:w-[30%] p-4 lg:p-8 bg-white shadow-lg overflow-y-auto max-h-screen sticky top-0">
          {/* Progress Bar */}
          <div className="mb-4 pt-12">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-600">
                Course Progress
              </span>
              <span className="text-sm font-bold text-violet-700">
                {courseProgress}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-violet-600 h-2.5 rounded-full"
                style={{ width: `${courseProgress}%` }}
              ></div>
            </div>
          </div>

          {/* Course Modules */}
          <div className="no-scrollbar overflow-y-auto  max-h-[70vh]">
            <h2 className="text-lg font-bold text-violet-900 mb-4">
              Course Modules
            </h2>
            {mockData.map((module, moduleIndex) => (
              <div key={moduleIndex} className="mb-4">
                <motion.div
                  className={`flex items-center justify-between mb-2 ${
                    moduleIndex !== 0 ? 'cursor-pointer' : ''
                  }`}
                  onClick={() => moduleIndex !== 0 && toggleModuleExpansion(moduleIndex)}
                >
                  <div className="flex items-center space-x-2">
                    {module.icon}
                    <h3 className="text-md font-semibold text-gray-800">
                      {module.module_title}
                    </h3>
                  </div>
                  {moduleIndex !== 0 && ( // Only show toggle icon for non-first modules
                    module.isExpanded ? (
                      <ChevronDown className="w-5 h-5 text-gray-500" />
                    ) : (
                      <ChevronRight className="w-5 h-5 text-gray-500" />
                    )
                  )}
                </motion.div>
                <AnimatePresence>
                  {module.isExpanded && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {module?.lectures.map((lecture, lectureIndex) => (
                        <motion.div
                          key={lectureIndex}
                          className={`
                            px-3 py-2 rounded-lg cursor-pointer transition-colors flex justify-between items-center
                            ${
                              selectedLecture === lecture
                                ? "bg-violet-100 text-violet-800"
                                : "hover:bg-gray-100"
                            }
                          `}
                          whileTap={{ scale: 0.98 }}
                        >
                          <div
                            onClick={() => {
                              setSelectedModule(module);
                              setSelectedLecture(lecture);
                            }}
                            className="flex-grow"
                          >
                            <span className="text-sm">
                              {lecture.lecture_title}
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLectureCompletion(lecture);
                            }}
                            className="ml-2"
                          >
                            <CheckCircle2
                              className={`
                                w-5 h-5 
                                ${
                                  lecture.status
                                    ? "text-green-500"
                                    : "text-gray-300"
                                }
                              `}
                            />
                          </button>
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursePage;
