import React from 'react';
import { motion } from 'framer-motion';
import {
  BellIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../../utils/themeConfig';

const AdminHeader = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <header className="bg-white border-b border-gray-200 fixed top-0 right-0 left-0 z-20 md:left-64">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-end h-16">
          {/* Right side */}
          <div className="flex items-center space-x-4">
            {/* Dark mode toggle */}
            {/* <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={toggleTheme}
              className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
            >
              {theme === 'dark' ? (
                <SunIcon className="h-6 w-6" />
              ) : (
                <MoonIcon className="h-6 w-6" />
              )}
            </motion.button> */}

            {/* Notifications */}
            {/* <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none relative"
            >
              <BellIcon className="h-6 w-6" />
              <span className="absolute top-1.5 right-1.5 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />
            </motion.button> */}

            {/* Profile dropdown */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="relative"
            >
              <button className="flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 focus:outline-none">
                {/* <UserCircleIcon className="h-8 w-8 text-gray-400" /> */}
                <img 
                src="/logo.svg" 
                alt="Digital Do Logo" 
                className="h-9 w-9 bg-white rounded-full border-black border-[.001rem]"
              />
                <div className="hidden md:block text-left">
                  <p className="text-sm font-medium text-gray-700">Admin User</p>
                  {/* <p className="text-xs text-gray-500">View profile</p> */}
                </div>
              </button>
            </motion.div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader; 