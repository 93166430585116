import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import AdminLogin from '../admin/AdminLogin';
import config from '../../config/config';
import { setAdminStatus, setAdminUser, setAdminLoading } from '../../utils/reduxSlices/userSlice';
import { motion } from 'framer-motion';
import { ShieldExclamationIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

const AdminRoute = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.users.user);
  const { isAdmin, isLoading } = useSelector(state => state.users.admin);

  useEffect(() => {
    if (user) {
      dispatch(setAdminLoading(true));
      const adminEmails = config.adminEmails.split(',').map(email => email.trim());
      
      if (adminEmails.includes(user.email)) {
        dispatch(setAdminStatus(true));
        dispatch(setAdminUser(user));
      } else {
        dispatch(setAdminStatus(false));
        dispatch(setAdminUser(null));
      }
      dispatch(setAdminLoading(false));
    }
  }, [user, dispatch]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-col items-center"
        >
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-violet-500 mb-4"></div>
          <p className="text-gray-600">Verifying access...</p>
        </motion.div>
      </div>
    );
  }

  if (!user) {
    return <AdminLogin />;
  }

  if (!isAdmin) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md w-full bg-white rounded-lg shadow-xl p-8"
        >
          <div className="flex flex-col items-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 15 }}
              className="bg-red-100 p-3 rounded-full mb-4"
            >
              <ShieldExclamationIcon className="w-12 h-12 text-red-600" />
            </motion.div>
            
            <motion.h2 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-2xl font-bold text-gray-900 mb-2 text-center"
            >
              Access Restricted
            </motion.h2>
            
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="text-gray-600 text-center mb-6"
            >
              Sorry, you don't have permission to access the admin panel. Please contact your administrator if you believe this is a mistake.
            </motion.p>

            <motion.button 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
              onClick={() => window.location.href = '/'}
              className="flex items-center justify-center px-6 py-3 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors duration-300 shadow-md hover:shadow-lg group"
            >
              <ArrowLeftIcon className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform duration-300" />
              Return to Homepage
            </motion.button>
          </div>
        </motion.div>
      </div>
    );
  }

  return children;
};

export default AdminRoute; 