import React from "react";

const ShimmerUICourseCard = () => {
  return (
    <div className="flex flex-wrap justify-between gap-y-10 animate-pulse">
      <div className=" w-full md:w-72">
        <div className="h-[26rem] rounded-b-xl shadow-md">
          <div className=" rounded-t-xl h-48 bg-gray-200"></div>
          <div className="bg-gray-200 mt-5 h-6  w-[89%] rounded-full mx-auto"></div>
          <div className="bg-gray-200 mt-3 h-6  w-[89%] rounded-full mx-auto"></div>
          <div className="bg-gray-200 mt-6 h-3  w-[89%] rounded-full mx-auto"></div>
          <div className="bg-gray-200 mt-1 h-3  w-[89%] rounded-full mx-auto"></div>
          <div className="flex ">
            <div className="bg-gray-200 mt-9 h-6  w-[30%] rounded-full me-auto ml-4"></div>
            <div className="bg-gray-200 mt-9 h-6  w-[50%] rounded-full me-auto ml-4"></div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ShimmerUICourseCard;
