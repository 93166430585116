import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { firebaseAuth } from '../../utils/firebase';
import { addUser, removeUser, setAdminStatus, setAdminUser } from '../../utils/reduxSlices/userSlice';
import { onAuthStateChanged } from 'firebase/auth';
import config from '../../config/config';

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        dispatch(addUser({
          email: user.email,
          uid: user.uid,
          displayName: user.displayName
        }));

        const adminEmails = config.adminEmails.split(',').map(email => email.trim());
        if (adminEmails.includes(user.email)) {
          dispatch(setAdminStatus(true));
          dispatch(setAdminUser({
            email: user.email,
            uid: user.uid,
            displayName: user.displayName,
          }));
        }
      } else {
        dispatch(removeUser());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return children;
};

export default AuthProvider; 