import { createSlice } from "@reduxjs/toolkit";



export const userSlice = createSlice({
    name:"users",
    initialState:{
        TotalUsers:[],
        user:[],
        purchaseCourse:[],
        myCourse:[],
        admin: {
            isAdmin: false,
            adminUser: null,
            isLoading: true
        }
    },
    reducers:{
        addTotalUser:(state,action)=>{
            state.TotalUsers.push(action.payload) 
        },
        addUser:(state,action)=>{
            state.user=action.payload
        },
        removeUser:(state)=>{
            state.user=[];
            state.admin.isAdmin = false;
            state.admin.adminUser = null;
        },
        addPurchasedCourse:(state,action)=>{
            state.purchaseCourse=action.payload
        },
        addMyCourse:(state,action)=>{
            state.myCourse=action.payload
        },
        setAdminStatus: (state, action) => {
            state.admin.isAdmin = action.payload;
        },
        setAdminUser: (state, action) => {
            state.admin.adminUser = action.payload;
        },
        setAdminLoading: (state, action) => {
            state.admin.isLoading = action.payload;
        }
    }
})

export const {
    addUser,
    removeUser,
    addTotalUser,
    addMyCourse,
    addPurchasedCourse,
    setAdminStatus,
    setAdminUser,
    setAdminLoading
} = userSlice.actions
export default userSlice.reducer