import React, { useEffect, useState } from "react";
import AuthComponent from "./AuthComponent";
import useGetCourses, { useGetUser } from "../../utils/firebaseDataFetch/useGetCourses";
import { useDispatch, useSelector } from "react-redux";
import { auth, firebaseAuth } from "../../utils/firebase";
import { addUser } from "../../utils/reduxSlices/userSlice";
import { useLocation } from "react-router-dom";
import useUserFromLocal from "../../utils/useUserFromLocal";
import SideBarLMS from "../../lms/SideBarLMS";
import { onAuthStateChanged } from "firebase/auth";
import ComingSoon from "../../utils/ComingSoon";







const MainComponent = () => {

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        console.log('User authenticated:', user);
        // Additional actions for authenticated user
      } else {
        console.log('No user authenticated');
        // Additional actions for no user
      }
    });

    return () => unsubscribe();
  }, []);

  useGetCourses()
  const getUserData= useUserFromLocal()
  


  const dispatch = useDispatch()
  const userData= useSelector(store=>store.users.user)

  // useGetUser()


  // const userData =  auth.currentUser
  // dispatch(addUser(userData))

 
  // console.log(user)

  // console.log(userData)

  // window.addEventListener('storage',(e)=>{
  //   if(e.key==="getUserData"){
  //     dispatch(addUser(JSON.parse(e.newValue)))
  //   }
  // })

 
  
 
   
 
    
  
  
  
  

 

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  return (
    <>
      <ComingSoon />
      {/* <SideBarLMS/> */}
      
        {isLoginOpen && (
          <AuthComponent
            isButtonLogin={false}
            onClose={() => setIsLoginOpen(false)}
          />
        )}
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-48">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Announcing our next round of workshops.{" "}
                <a href="#" className="font-semibold text-indigo-600">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <div className="text-center">
              <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                Your Journey to Expertise Starts Here.
              </h1>
              <p className="mt-8 text-pretty text-xs md:text-lg font-medium text-gray-500 sm:text-xl/8">
                Transform the Way You Learn, Grow, and Succeed.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <div
                  onClick={() => setIsLoginOpen(!isLoginOpen)}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Enroll Now
                </div>
                <a href="#" className="text-sm/6 font-semibold text-gray-900">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>
    </>
  );
};

export default MainComponent;
