import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { firebaseUploadUser } from "../../utils/firebaseDataFetch/uploadCourse";
import { GraduationCap, Brain, Target } from "lucide-react";
import { addUser } from "../../utils/reduxSlices/userSlice";

const AuthPage = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    }
  };

  const features = [
    {
      icon: GraduationCap,
      title: "Expert Instructors",
      description: "Learn from industry professionals"
    },
    {
      icon: Brain,
      title: "Interactive Learning",
      description: "Engage with hands-on projects"
    },
    {
      icon: Target,
      title: "Career Growth",
      description: "Achieve your professional goals"
    }
  ];

  const handleUserData = async(user) => {
    const userData = {
      userId: user.uid,
      email: user.email,
      name: user.displayName,
      photoURL: user.photoURL || null,
    };

    await firebaseUploadUser(userData).then(() => { 
      try {
        localStorage.setItem("getUserData", JSON.stringify(userData));
        dispatch(addUser(userData));
        navigate("/");
      } catch (error) {
        console.error("Error storing user data:", error);
      }
    });
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      handleUserData(result.user);
    } catch (error) {
      setError(error.code === "auth/popup-closed-by-user" 
        ? "Sign-in cancelled" 
        : "Unable to sign in. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      className="min-h-screen flex flex-col md:flex-row"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/* Auth Section - Appears First on Mobile */}
      <div className="p-8 w-full md:w-1/2 flex items-center justify-center bg-gradient-to-br from-violet-50 to-indigo-50 min-h-screen md:min-h-0">
        <motion.div
          variants={itemVariants}
          className="w-full max-w-md space-y-8 bg-white p-8 rounded-2xl shadow-xl"
        >
          <motion.div variants={itemVariants} className="text-center">
            <div className="flex justify-center mb-6">
              <img 
                src="/logo.svg" 
                alt="Digital Do Logo" 
                className="h-16 w-auto"
              />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-2">
              Get Started
            </h2>
            <p className="text-gray-600">
              Continue with Google to access your account
            </p>
          </motion.div>

          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-red-50 text-red-600 p-3 rounded-lg flex items-center gap-2"
            >
              <XMarkIcon className="w-5 h-5 flex-shrink-0" />
              <span>{error}</span>
            </motion.div>
          )}

          <motion.div variants={itemVariants}>
            <button
              onClick={handleGoogleSignIn}
              disabled={loading}
              className="w-full flex items-center justify-center gap-3 py-3 px-4 border border-gray-300 rounded-lg shadow-sm bg-white hover:bg-gray-50 transition-all duration-300 group disabled:opacity-70"
            >
              {loading ? (
                <div className="flex items-center gap-3">
                  <div className="w-5 h-5 border-2 border-gray-300 border-t-violet-600 rounded-full animate-spin" />
                  <span className="text-gray-600">Connecting...</span>
                </div>
              ) : (
                <>
                  <svg className="h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                    <path d="M12 23c2.97 0 5.46-1 7.28-2.69l-3.57-2.77c-.99.69-2.26 1.1-3.71 1.1-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C4.09 20.53 7.79 23 12 23z" fill="#34A853"/>
                    <path d="M5.84 14.11c-.22-.69-.35-1.43-.35-2.11s.13-1.42.35-2.11V7.05H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.95l2.66-2.84z" fill="#FBBC05"/>
                    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.46 2.09 14.97 1 12 1 7.79 1 4.09 3.47 2.18 7.05l3.66 2.84c.87-2.6 3.3-4.51 6.16-4.51z" fill="#EA4335"/>
                  </svg>
                  <span className="text-gray-700">Continue with Google</span>
                  <ArrowRightIcon className="w-5 h-5 text-gray-400 group-hover:translate-x-1 transition-transform duration-300" />
                </>
              )}
            </button>
          </motion.div>
        </motion.div>
      </div>

      {/* Features Section - Appears Second on Mobile */}
      <div className="bg-gradient-to-br from-violet-600 to-indigo-700 text-white p-8 w-full md:w-1/2 flex items-center min-h-screen md:min-h-0">
        <div className="relative z-10 max-w-xl mx-auto">
          <motion.div 
            variants={itemVariants} 
            className="mb-8 md:mb-12 text-center"
          >
            <h1 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6">
              Welcome to Digital Do
            </h1>
            <p className="text-base md:text-xl text-violet-100">
              Join our community of learners and start your journey to success
            </p>
          </motion.div>

          <div className="space-y-6 md:space-y-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="flex items-start space-x-4 group"
                whileHover={{ x: 10 }}
              >
                <div className="flex-shrink-0 p-2 md:p-3 bg-white/10 rounded-lg group-hover:bg-white/20 transition-colors duration-300">
                  <feature.icon className="w-5 h-5 md:w-6 md:h-6" />
                </div>
                <div>
                  <h3 className="font-semibold text-base md:text-lg mb-1">
                    {feature.title}
                  </h3>
                  <p className="text-sm md:text-base text-violet-200">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AuthPage;