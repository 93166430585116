import { motion } from "framer-motion";

const CoursePageSkeleton = () => {
  return (
    <div className="flex flex-wrap lg:flex-nowrap relative animate-pulse">
      {/* Left Section Skeleton */}
      <div className="w-full lg:w-[70%] p-4 lg:p-8">
        {/* Title Skeleton */}
        <div className="h-8 bg-gray-200 rounded-lg w-3/4 mb-4"></div>

        {/* Video Player Skeleton */}
        <div className="w-full aspect-video bg-gray-200 rounded-lg mb-4"></div>

        {/* Navigation Tabs Skeleton */}
        <div className="flex space-x-4 mb-6">
          {[1, 2, 3, 4].map((item) => (
            <div
              key={item}
              className="h-10 w-24 bg-gray-200 rounded-full"
            ></div>
          ))}
        </div>

        {/* Content Section Skeleton */}
        <div className="space-y-4">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>

        {/* Feedback Button Skeleton */}
        <div className="w-full h-12 bg-gray-200 rounded-lg mt-6"></div>
      </div>

      {/* Right Section Skeleton */}
      <div className="w-full lg:w-[30%] p-4 lg:p-8 bg-white">
        {/* Progress Bar Skeleton */}
        <div className="mb-8 pt-12">
          <div className="flex justify-between mb-2">
            <div className="h-4 w-24 bg-gray-200 rounded"></div>
            <div className="h-4 w-12 bg-gray-200 rounded"></div>
          </div>
          <div className="w-full h-2.5 bg-gray-200 rounded-full"></div>
        </div>

        {/* Modules Skeleton */}
        <div className="space-y-6">
          {[1, 2, 3].map((module) => (
            <motion.div
              key={module}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: module * 0.1 }}
              className="space-y-4"
            >
              {/* Module Header */}
              <div className="flex items-center space-x-2">
                <div className="h-5 w-5 bg-gray-200 rounded"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              </div>

              {/* Module Lectures */}
              <div className="space-y-2 pl-7">
                {[1, 2, 3].map((lecture) => (
                  <div
                    key={lecture}
                    className="flex items-center justify-between p-2 bg-gray-100 rounded-lg"
                  >
                    <div className="h-3 bg-gray-200 rounded w-2/3"></div>
                    <div className="h-4 w-4 bg-gray-200 rounded-full"></div>
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Shimmer Effect Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/30 to-transparent skeleton-shimmer"></div>
    </div>
  );
};

export default CoursePageSkeleton; 