import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Award,
  BookOpen,
  Calendar,
  Clock,
  Flame,
  Bell,
  GraduationCap,
  TrendingUp,
  Target,
  Zap,
  CheckCircle2,
  BarChart3
} from 'lucide-react';
import { useSelector } from 'react-redux';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const learningData = [
  { day: 'Mon', hours: 2 },
  { day: 'Tue', hours: 3 },
  { day: 'Wed', hours: 1.5 },
  { day: 'Thu', hours: 4 },
  { day: 'Fri', hours: 2.5 },
  { day: 'Sat', hours: 3.5 },
  { day: 'Sun', hours: 2 },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
};

const Overview = () => {
  const getUser = useSelector((state) => state.users.user);
  const [quote] = useState("Learning is not attained by chance, it must be sought for with ardor and diligence.");

  const stats = [
    { icon: <Clock size={20} />, value: '48h', label: 'Total Hours' },
    { icon: <BookOpen size={20} />, value: '12', label: 'Courses' },
    { icon: <CheckCircle2 size={20} />, value: '85%', label: 'Completion' },
    { icon: <Flame size={20} />, value: '7', label: 'Day Streak' },
  ];

  const achievements = [
    { title: 'Quick Learner', description: 'Completed 5 courses in a month', icon: <Zap size={20} /> },
    { title: 'Dedicated Student', description: '7-day study streak', icon: <Target size={20} /> },
    { title: 'Knowledge Seeker', description: 'Watched 50+ lectures', icon: <GraduationCap size={20} /> },
  ];

  return (
    <motion.div 
      className="p-4 md:p-6 lg:p-8 bg-gray-50 min-h-screen"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Welcome Section */}
      <motion.div 
        variants={itemVariants}
        className="bg-gradient-to-r from-violet-600 to-indigo-600 rounded-2xl p-6 md:p-8 text-white mb-8 shadow-lg"
      >
        <h1 className="text-3xl md:text-4xl font-bold mb-2">
          Welcome back, {getUser?.name || 'Learner'}!
        </h1>
        <p className="text-violet-100 italic">{quote}</p>
      </motion.div>

      {/* Stats Grid */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
        {stats.map((stat, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            className="bg-white p-4 rounded-xl shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center space-x-3 text-violet-600 mb-2">
              {stat.icon}
              <span className="text-2xl font-bold">{stat.value}</span>
            </div>
            <p className="text-gray-600 text-sm">{stat.label}</p>
          </motion.div>
        ))}
      </div>

      {/* Learning Progress Chart */}
      <motion.div 
        variants={itemVariants}
        className="bg-white p-6 rounded-2xl shadow-lg mb-8"
      >
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-gray-800 flex items-center gap-2">
            <BarChart3 className="text-violet-600" />
            Learning Progress
          </h2>
        </div>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={learningData}>
              <defs>
                <linearGradient id="colorHours" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#7c3aed" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#7c3aed" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="hours"
                stroke="#7c3aed"
                fillOpacity={1}
                fill="url(#colorHours)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </motion.div>

      {/* Achievements and Notifications Grid */}
      <div className="grid md:grid-cols-2 gap-6">
        {/* Achievements */}
        <motion.div 
          variants={itemVariants}
          className="bg-white p-6 rounded-2xl shadow-lg"
        >
          <h2 className="text-xl font-bold text-gray-800 flex items-center gap-2 mb-4">
            <Award className="text-violet-600" />
            Recent Achievements
          </h2>
          <div className="space-y-4">
            {achievements.map((achievement, index) => (
              <motion.div
                key={index}
                className="flex items-start space-x-3 p-3 rounded-lg bg-violet-50 hover:bg-violet-100 transition-colors"
                whileHover={{ x: 5 }}
              >
                <div className="text-violet-600">{achievement.icon}</div>
                <div>
                  <h3 className="font-semibold text-violet-900">{achievement.title}</h3>
                  <p className="text-sm text-violet-600">{achievement.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Notifications */}
        <motion.div 
          variants={itemVariants}
          className="bg-white p-6 rounded-2xl shadow-lg"
        >
          <h2 className="text-xl font-bold text-gray-800 flex items-center gap-2 mb-4">
            <Bell className="text-violet-600" />
            Latest Updates
          </h2>
          <div className="space-y-4">
            {[1, 2, 3].map((_, index) => (
              <motion.div
                key={index}
                className="p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors"
                whileHover={{ x: 5 }}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium text-gray-800">New Course Available</h3>
                    <p className="text-sm text-gray-600 mt-1">
                      Check out our latest course on Advanced React Patterns
                    </p>
                  </div>
                  <span className="text-xs text-violet-600 bg-violet-100 px-2 py-1 rounded-full">
                    New
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Overview;