import React, { useEffect, useState } from "react";
import SideBarLMS from "./SideBarLMS";
import { useGetUser } from "../utils/firebaseDataFetch/useGetCourses";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { auth, firebaseAuth } from "../utils/firebase";
import { addUser } from "../utils/reduxSlices/userSlice";
import useUserFromLocal from "../utils/useUserFromLocal";
import Overview from "./Overview";
import MyCourses from "./subComponents/MyCourses";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { getCrossDomainAuth, setCrossDomainAuth } from '../utils/crossDomainAuth';


const MainLMS = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isActiveSection, setIsActiveSection] = useState("overview");
  const [isLoading, setIsLoading] = useState(true);
  const user = useUserFromLocal();

  useEffect(() => {
    let authTimeout;
    let unsubscribe;

    const checkAuth = async () => {
      authTimeout = setTimeout(async () => {
        const currentUser = firebaseAuth.currentUser;
        const storedUser = localStorage.getItem("getUserData");
        const cookies = document.cookie.split(';');
        const authCookie = cookies.find(c => c.trim().startsWith('authUser='));

        if (!currentUser && !storedUser && !authCookie) {
          console.log('No authentication found');
          navigate("/auth");
        } else {
          console.log('User is authenticated');
        }
        setIsLoading(false);
      }, 2000);

      unsubscribe = onAuthStateChanged(firebaseAuth, (firebaseUser) => {
        if (firebaseUser) {
          setIsLoading(false);
          clearTimeout(authTimeout);
        }
      });
    };

    checkAuth();

    return () => {
      if (unsubscribe) unsubscribe();
      if (authTimeout) clearTimeout(authTimeout);
    };
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const Dashboard = () => {
    switch (isActiveSection) {
      case "overview":
        return <Overview />;

      case "courses":
        return <MyCourses />;

      default:
        return null
    }
  };
  


  return (
    <div>
      <SideBarLMS
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isActiveSection={isActiveSection}
        setIsActiveSection={setIsActiveSection}
      />
      <div className={`${isCollapsed ? "sm:pl-[6%]" : "sm:pl-[18%]"}`}>
        <Dashboard/>
      </div>
    </div>
  );
};

export default MainLMS;
