import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import {
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { firebaseUploadUser } from "../../utils/firebaseDataFetch/uploadCourse";
import { firebaseAuth } from "../../utils/firebase";
import { setCrossDomainAuth } from '../../utils/crossDomainAuth';
import { getCurrentUserData } from "../../utils/firebase";

const AuthComponent = ({ onClose, isButtonLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const [isLogin, setIsLogin] = useState(isButtonLogin ? true : false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);



  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 100,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.3 },
    },
  };

  const handleUserData = async(user) => {
    const userData = {
      userId: user.uid,
      email: user.email,
      name: user.displayName || formData.name,
      photoURL: user.photoURL || null,
    };

    try {
      // Upload user data first
      await firebaseUploadUser(userData);
      
      // Store auth data in both localStorage and sessionStorage
      localStorage.setItem("getUserData", JSON.stringify(userData));
      sessionStorage.setItem("isAuthenticated", "true");

      // Set a cookie that works across subdomains
      const domain = window.location.hostname;
      const rootDomain = domain.split('.').slice(-2).join('.');
      document.cookie = `authUser=${JSON.stringify(userData)}; path=/; domain=.${rootDomain}`;

      // Simply close the popup without redirecting
      onClose(false);

    } catch (error) {
      console.error("Error handling user data:", error);
      firebaseErrorCheck(error.message);
    }
  };

  const firebaseErrorCheck=(error)=>{
   
    switch (error) {
      case "Firebase: Error (auth/email-already-in-use).":
        setError("Email already in use");
        break;
      case "Firebase: Password should be at least 6 characters (auth/weak-password).":
        setError("Password should be at least 6 characters");
        break;
      case "Firebase: Error (auth/invalid-email).":
        setError("Invalid email format");
        break;
      case "Firebase: Error (auth/user-not-found).":
        setError("User not found. Please check your email or sign up.");
        break;
      case "Firebase: Error (auth/wrong-password).":
        setError("Incorrect password. Please try again.");
        break;
      case "Firebase: Error (auth/too-many-requests).":
        setError("Too many failed attempts. Please try again later.");
        break;
      case "Firebase: Error (auth/network-request-failed).":
        setError("Network error. Please check your internet connection.");
        break;
      case "Firebase: Error (auth/operation-not-allowed).":
        setError("This operation is not allowed. Please contact support.");
        break;
      case "Firebase: Error (auth/requires-recent-login).":
        setError("Please log in again to perform this operation.");
        break;
      case "Firebase: Error (auth/account-exists-with-different-credential).":
        setError("An account already exists with a different login method. Try using that method to sign in.");
        break;
        case "Firebase: Error (auth/missing-password).":
        setError("Missing Password");
        break;
        case "Firebase: Error (auth/popup-closed-by-user).":
          setError("Google Login Popup Closed");
          break;
        
      default:
        
        setError(error.message);
    }

  }



  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      await handleUserData(result.user);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      handleUserData(result.user);
    } catch (error) {
      // setError(error.message);
      firebaseErrorCheck(error.message)
    } finally {
      setLoading(false);
    }
  };

  const passwordValidation = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return regex.test(password);
  };


  
  

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    if (!passwordValidation(formData.password)) {
      setError("Password must be at least 6 characters, include uppercase, lowercase, a number, and a special character.");
      return;
    }

    try {
      setLoading(true);
      const result = await createUserWithEmailAndPassword(
        auth,
        formData?.email,
        formData?.password
      );
      handleUserData(result.user);
      // console.log(result.user)
    } catch (error) {
      // console.log(error.message)

      firebaseErrorCheck(error.message)



     
      // if(error.message=="Firebase: Error (auth/email-already-in-use)."){
      //   setError("Email already in use")
      // }else{
      //   setError(error.message);
      // }
      
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="fixed w-full z-50 min-h-screen flex items-center justify-center backdrop-blur-sm  -mt-12 md:mt-0 p-4">
      <motion.div
        className="w-full max-w-md bg-white rounded-2xl shadow-2xl overflow-hidden"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {/* Close Button */}

        <div className=" relative p-8">
          <AnimatePresence mode="popLayout">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 z-10 text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6 " />
            </button>
            {isLogin ? (
              <motion.div
                key="login"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
                  Login to Your Account
                </h2>

                <form onSubmit={handleEmailSignIn} className="space-y-4">
                  {/* Email Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email Address"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                    {/* // Add loading and error states to UI: */}
                   
                   
                  </div>

                  {/* Password Input */}
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <LockClosedIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="password"
                      placeholder="Password"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                  </div>
                  {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-sm text-red-600 mt-1"
        >
          {error}
        </motion.div>
      )}
                  {loading && <div className="text-center">Loading...</div>}

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="w-full bg-violet-600 text-white py-2 rounded-lg hover:bg-violet-700 transition-colors"
                  >
                    Login
                  </button>

                  {/* Google Sign-In */}
                  <div className="relative my-4">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-4 bg-white text-gray-500">or</span>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={handleGoogleSignIn}
                    className="w-full flex justify-center items-center bg-white border border-gray-300 text-gray-700 py-2 rounded-lg hover:bg-gray-50 transition-colors space-x-2"
                  >
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c2.97 0 5.46-1 7.28-2.69l-3.57-2.77c-.99.69-2.26 1.1-3.71 1.1-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C4.09 20.53 7.79 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M5.84 14.11c-.22-.69-.35-1.43-.35-2.11s.13-1.42.35-2.11V7.05H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.95l2.66-2.84z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.46 2.09 14.97 1 12 1 7.79 1 4.09 3.47 2.18 7.05l3.66 2.84c.87-2.6 3.3-4.51 6.16-4.51z"
                        fill="#EA4335"
                      />
                    </svg>
                    <span>Sign in with Google</span>
                  </button>

                  {/* Switch to Signup */}
                  <div className="text-center mt-4">
                    <span className="text-gray-600">
                      Don't have an account?{" "}
                      <button
                        type="button"
                        onClick={() => {setIsLogin(false); setError("")}}
                        className="text-violet-600 hover:text-violet-800 font-semibold"
                      >
                        Sign Up
                      </button>
                    </span>
                  </div>
                </form>
              </motion.div>
            ) : (
              <motion.div
  key="signup"
  initial={{ opacity: 0, x: 50 }}
  animate={{ opacity: 1, x: 0 }}
  exit={{ opacity: 0, x: -50 }}
  transition={{ duration: 0.3 }}
>
  <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
    Create an Account
  </h2>

  <form onSubmit={handleEmailSignUp} className="space-y-4">
    {/* Name Input */}
    {/* <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <UserIcon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        name="name"
        onChange={handleInputChange}
        value={formData?.name}
        placeholder="Full Name"
        required
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
      />
    </div> */}

    {/* Email Input */}
    {/* HIDE MANUAL FORM FOr NOW || ONLY SHOWS GOOGLE SIGNIN  */}
    {/* <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <EnvelopeIcon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="email"
        name="email"
        onChange={handleInputChange}
        value={formData?.email}
        placeholder="Email Address"
        required
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
      />
    </div> */}

    {/* Password Input */}
    {/* <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <LockClosedIcon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="password"
        name="password"
        value={formData?.password}
        onChange={handleInputChange}
        placeholder="Password"
        required
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
      />
      
     
    </div> */}
    {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-sm text-red-600 mt-1"
        >
          {error}
        </motion.div>
      )}

    {/* Submit Button */}
    {/* <button
      type="submit"
      className="w-full bg-violet-600 text-white py-2 rounded-lg hover:bg-violet-700 transition-colors"
    >
      Sign Up
    </button> */}

    {/* Google Sign-Up */}
    {/* <div className="relative my-4">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-t border-gray-300"></div>
      </div>
      <div className="relative flex justify-center">
        <span className="px-4 bg-white text-gray-500">or</span>
      </div>
    </div> */}

    <button
      type="button"
      onClick={handleGoogleSignIn}
      className="w-full flex justify-center items-center bg-white border border-gray-300 text-gray-700 py-2 rounded-lg hover:bg-gray-50 transition-colors space-x-2"
    >
      <svg
        className="w-5 h-5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#4285F4"
        />
        <path
          d="M12 23c2.97 0 5.46-1 7.28-2.69l-3.57-2.77c-.99.69-2.26 1.1-3.71 1.1-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C4.09 20.53 7.79 23 12 23z"
          fill="#34A853"
        />
        <path
          d="M5.84 14.11c-.22-.69-.35-1.43-.35-2.11s.13-1.42.35-2.11V7.05H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.95l2.66-2.84z"
          fill="#FBBC05"
        />
        <path
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.46 2.09 14.97 1 12 1 7.79 1 4.09 3.47 2.18 7.05l3.66 2.84c.87-2.6 3.3-4.51 6.16-4.51z"
          fill="#EA4335"
        />
      </svg>
      <span>Sign up with Google</span>
    </button>

    {/* Switch to Login */}
    {/* <div className="text-center mt-4">
      <span className="text-gray-600">
        Already have an account?{" "}
        <button
          type="button"
          onClick={() => {setIsLogin(true); setError('')}}
          className="text-violet-600 hover:text-violet-800 font-semibold"
        >
          Log In
        </button>
      </span>
    </div> */}
  </form>
</motion.div>

            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  );
};

export default AuthComponent;
