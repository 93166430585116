import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseApp as app } from "../../../utils/firebase";
import { firebaseAuth } from "../../../utils/firebase";

const storage = getStorage(app);

// Upload thumbnail image and get download URL
export const uploadThumbnailToStorage = async (file, courseId) => {
  try {
    // Check if user is authenticated
    const user = firebaseAuth.currentUser;
    if (!user) {
      throw new Error("User must be authenticated to upload files");
    }

    if (!file) return null;

    // Create a storage reference with user ID in path
    const storageRef = ref(
      storage, 
      `thumbnails/${courseId}_${Date.now()}.webp`
    );

    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (error) {
    console.error("Error uploading thumbnail:", error);
    throw new Error(error.message || "Failed to upload thumbnail");
  }
};

// Convert base64/dataURL to file object
export const dataURLtoFile = (dataurl, filename) => {
  try {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while(n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, { type: mime });
  } catch (error) {
    console.error("Error converting data URL to file:", error);
    throw new Error("Failed to process image");
  }
}; 