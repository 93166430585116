import React from "react";
import CourseCard from "./CourseCard";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ShimmerUICourseCard from "../../utils/ShimmerUICourseCard";
import useGetCourses from "../../utils/firebaseDataFetch/useGetCourses";

const WhatYouWillLearn = () => {
  const navigate = useNavigate();
  const isLoading = useGetCourses();
  const courses = useSelector((state) => state.course.courses);

  const renderContent = () => {
    if (isLoading || !courses || courses.length === 0) {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 py-10">
          {[...Array(4)].map((_, index) => (
            <ShimmerUICourseCard key={index} />
          ))}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-10">
        {courses.map((course) => (
          <div
            key={course?.id}
            className="w-full cursor-pointer"
            onClick={() => {
              navigate(`courseDetails/${course?.id}`, { state: { course } });
              window.scrollTo(0, 0);
            }}
          >
            <CourseCard course={course} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">
          What You'll Learn
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Master the tools and skills that top designers use.
        </p>
      </div>

      {renderContent()}

      <div className="flex justify-center">
        <button
          onClick={() => navigate("/courses")}
          className="flex items-center justify-center px-8 py-3 bg-violet-600 text-white rounded-full font-semibold hover:bg-violet-700 transition-all duration-300 shadow-md hover:shadow-xl group"
        >
          View All Courses
          <ArrowRightIcon className="w-5 h-5 ml-3 transition-transform duration-300 group-hover:translate-x-1" />
        </button>
      </div>
    </div>
  );
};

export default WhatYouWillLearn;
