import React, { useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import {
  PencilIcon,
  TrashIcon,
  PlayCircleIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import UpdateLecture from "./UpdateLecture";

import DeleteModal from "./DeleteModal";
import { firebaseDeleteAny } from "../../../utils/firebaseDataFetch/uploadCourse";

const Lectures = ({ courseId, doc_id }) => {
  const lecturesBeforeFilter = useSelector((state) => state?.course.lecture);

  // Filter and sort lectures
  const lectures = lecturesBeforeFilter
    .filter((lecture) => lecture?.course_doc_id == doc_id)
    .sort((a, b) => {
      const orderA = Number(a?.order) || 0;
      const orderB = Number(b?.order) || 0;
      return orderA - orderB;
    });

  const [handleUpdateLectureModal, setHandleUpdateLectureModal] =
    useState(false);
  const [lectureProps, setLectureProps] = useState(null);

  const [isDeleteLectureModal, setIsDeleteLectureModal] = useState(false);
  const [selectedCourse,setSelectedCourse]=useState(null)
  const [deleteDocId,setDeleteDocId]=useState(null)

  // console.log(lectures)

  const handleDeleteLecture = (id,tittle) => {
    // Implement delete logic
   
    setDeleteDocId(id)
    setSelectedCourse(tittle)
    setIsDeleteLectureModal(true);
    // console.log('Deleting course:', courseId)
  };

  const handleEditLecture = (lecture) => {
    setHandleUpdateLectureModal(!handleUpdateLectureModal);
    setLectureProps(lecture);
  };

  const confirmDelete = (id) => {

    firebaseDeleteAny("lecture",id)
    setIsDeleteLectureModal(false)

    // console.log(`Course ${id} deleted`);
    // Call your delete logic here
    // setModalOpen(false);
  };


  return (
    <>
      {isDeleteLectureModal && <DeleteModal 
      isOpen={isDeleteLectureModal}
      onClose={()=>setIsDeleteLectureModal(false)}
      onConfirm={()=>confirmDelete(deleteDocId)}
      courseName={selectedCourse}
      
      />}
      {handleUpdateLectureModal && (
        <UpdateLecture
          setLectureProps={setLectureProps}
          setHandleUpdateLectureModal={setHandleUpdateLectureModal}
          props={lectureProps}
        />
      )}
      <div className="flex justify-start items-center  py-2">
        <div className="space-y-2">
          {lectures.map((lecture, index) => (
            <div
              key={lecture?.doc_id}
              className="flex justify-start items-center gap-x-24  border-b-2 py-1 "
            >
              <div className="flex w-3/4 items-center gap-x-2">
                <PlayCircleIcon className="w-5 h-5" />
                {lecture?.lecture_title}
              </div>

              <div className="flex gap-x-10">
                <motion.button
                  onClick={() => handleEditLecture(lecture)}
                  className="text-blue-500 hover:text-blue-700"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <PencilIcon className="w-5 h-5" />
                </motion.button>

                <motion.button
                  onClick={() => handleDeleteLecture(lecture?.doc_id, lecture?.lecture_title)}
                  className="text-red-500 hover:text-red-700"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <TrashIcon className="w-5 h-5" />
                </motion.button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Lectures;
