import { addDoc, collection, doc, updateDoc,deleteDoc } from "firebase/firestore"
import { firebaseDb } from "../firebase"





//UPDATES-------------------------
export const firebaseUpdateCourse = async(courseID, submissionData)=>{

    const docRef = doc(firebaseDb,"course",courseID)
    await updateDoc(docRef,submissionData)
  

}

export const firebaseUpdateLecture = async(doc_id, formData)=>{

  const docRef = doc(firebaseDb,"lecture",doc_id)
  await updateDoc(docRef,formData)

}

export const firebaseUpdateModule = async(doc_id, formData)=>{

  const docRef = doc(firebaseDb,"module",doc_id)
  await updateDoc(docRef,formData)

}

export const firebaseDeleteAny = async (documentName,doc_id) => {
  try {
    const docRef = doc(firebaseDb, documentName, doc_id);
    await deleteDoc(docRef);
    console.log(`Document with ID ${doc_id} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting document:", error);
  }
};






//UPDATES END-------------------------


//UPLOAD -----------------------------


const uploadCourse = async (courseData) => {
  try {
    const coursesRef = collection(firebaseDb, "course");
    const docRef = await addDoc(coursesRef, {
      ...courseData,
      createdAt: new Date(),
    });
    console.log("Course uploaded successfully with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error uploading course:", error);
    throw error;
  }
};

export const firebaseUploadUser=async(props)=>{
  // console.log(props)
 

  const upload = await addDoc(collection(firebaseDb,"user"),props)
      // console.log("uploaded", upload.id)
  
  
  }

export const uploadCourseModule = async (props) => {
    try {
      // Add the document to the "module" collection
      const upload = await addDoc(collection(firebaseDb, "module"), props);
  
      // Add the generated document ID as the "doc_id" field
      await updateDoc(doc(firebaseDb, "module", upload.id), { doc_id: upload.id });
  
      // console.log("Uploaded with ID:", upload.id);
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  export const uploadCourseLecture = async (props) => {
    try {
      // Add the document to the "module" collection
      const upload = await addDoc(collection(firebaseDb, "lecture"), props);
  
      // Add the generated document ID as the "doc_id" field
      await updateDoc(doc(firebaseDb, "lecture", upload.id), { doc_id: upload.id });
  
      // console.log("Uploaded with ID:", upload.id);
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };





export default uploadCourse