import './App.css';

import { createBrowserRouter, Outlet, RouterProvider, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import CourseSection from './components/subComponents/CourseSection';

import PolicyPage from './components/subComponents/PolicyPage';
import CourseDetails from './components/subComponents/CourseDetails';
import TeamPage from './components/subComponents/TeamPage';
import Header from './components/subComponents/Header';
import Footer from './components/subComponents/Footer';
import { Provider } from 'react-redux';
import { appStore } from './utils/appStore';

import AdminDashboard from './components/admin/AdminDashboard';

import CreateCoursePage from './components/admin/CreateCoursePage';
import UpdateCourse from './components/admin/UpdateCourse';
import AddModule from './components/admin/AddModule';
import MainLMS from './lms/MainLMS';
import AuthPage from './components/subComponents/AuthPage';
import CoursePage from './lms/CoursePage';
import ErrorBoundary from './utils/ErrorBoundary';
import AdminRoute from './components/auth/AdminRoute';
import AdminLogin from './components/admin/AdminLogin';
import AuthProvider from './components/auth/AuthProvider';
import { ThemeProvider } from './utils/themeConfig';


const isLearningPlatform = window.location.hostname.startsWith('learn.');
const MainLayout=()=>{
 
  return(
    <div>
      <Header/>
      <main>
        <Outlet/>
      </main>
      <Footer/>
    </div>
  )
}

const AdminDashboardLayout=()=>{
  return(
    <div>
      <main>
        <Outlet/>
      </main>
    </div>
  )
}



const appRoute = createBrowserRouter(
  
  isLearningPlatform ? [
    {
      path:"/",
      element:<Outlet/>,
      errorElement:<ErrorBoundary/>,
      children:[
        {
          path:"",
          element:<MainLMS/>

        },
        {
          path:"auth",
          element:<AuthPage/>
        },
        {
          path:"course/:courseId/:courseTitle",
          element:<CoursePage/>
        },
        {
          path:"courseDetails/:uniqueCourseId",
          element:<CourseDetails/>,
          errorElement:<ErrorBoundary/>
        },
      ]
    }

  ]:
  
  [
  
  {
    path:"/",
    element:<MainLayout/>,
    children:[
      {
        path:"",
        element:<Home/>
      },
      {
        path:"coursePage",
        element:<CoursePage/>
      },
      {
        path:"courses",
        element:<CourseSection/>,
        
      },
      {
        path:"policies",
        element:<PolicyPage/>
      },
      {
        path:"courseDetails/:uniqueCourseId",
        element:<CourseDetails/>,
        errorElement:<ErrorBoundary/>
      },
      {
        path:"team",
        element:<TeamPage/>
    
      },
      {
        path:"refund_policies",
        element:<PolicyPage policyType = 'returnAndRefund' 
        customColor = 'violet' />
      },
{
        path:"terms",
        element:<PolicyPage policyType = 'termsAndConditions' 
        customColor = 'violet' />
      },
      
      
     
    ]
  },
  {
    path: "admin-login",
    element: <AdminLogin />
  },
  {
    path: "admin/*",
    element: (
      <AdminRoute>
        <AdminDashboardLayout />
      </AdminRoute>
    ),
    children: [
      {
        path: "",
        element: <AdminDashboard />
      },
      {
        path: "createcourse",
        element: <CreateCoursePage />
      },
      {
        path: "updateCourse",
        element: <UpdateCourse />
      },
      {
        path: "addModule",
        element: <AddModule />
      }
    ]
  },

 


])

function App() {
  return (
    <ThemeProvider>
      <Provider store={appStore}>
        <AuthProvider>
          <RouterProvider router={appRoute} />
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
