import { createSlice } from "@reduxjs/toolkit";

export const courseSlice = createSlice({
    name:"course",
    initialState:{
        courses:[],
        modules:[],
        lecture:[]
    },
    reducers:{
        addCourses: (state,action)=>{
            state.courses = action.payload;
        },
        addCourse: (state,action)=>{
            const existingIndex = state.courses.findIndex(
                course => course.id === action.payload.id
            );
            if (existingIndex === -1) {
                state.courses.push(action.payload);
            }
        },
        addCourseModule:(state,action)=>{
            state.modules=action.payload
            
           
       
            // const existingModuleIndex=state.modules.findIndex(
            //     module=>module?.doc_id===action.payload.doc_id
            // )
            // if(existingModuleIndex=== -1){
            //     state.modules.push(action.payload)
            // }
        },
        addCourseLecture:(state,action)=>{
            state.lecture=action.payload

            // state.lecture.push(action.payload)
            
            
       
            // const existingModuleIndex=state.lecture.findIndex(
            //     lecture=>lecture?.doc_id===action.payload.doc_id
            // )
            // if(existingModuleIndex=== -1){
            //     state.lecture.push(action.payload)
            // }
        }
    }
})

export const {addCourses, addCourse, addCourseModule, addCourseLecture}=courseSlice.actions
export default courseSlice.reducer