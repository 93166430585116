import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addUser } from "./reduxSlices/userSlice";
import { firebaseAuth, getCurrentUserData } from "./firebase";
import { onAuthStateChanged } from 'firebase/auth';

const useUserFromLocal = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    let unsubscribe;

    const handleAuthData = (user) => {
      if (user) {
        // Get user data from Firebase
        const userData = getCurrentUserData();
        if (userData) {
          setUserData(userData);
          dispatch(addUser(userData));
          localStorage.setItem("getUserData", JSON.stringify(userData));
          return true;
        }
      }
      
      // Try getting from localStorage if no Firebase user
      const storedUser = localStorage.getItem("getUserData");
      if (storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          setUserData(parsedUser);
          dispatch(addUser(parsedUser));
          return true;
        } catch (error) {
          console.error("Error parsing stored user:", error);
        }
      }
      
      return false;
    };

    // Listen for auth state changes
    unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      handleAuthData(user);
    });

    // Initial check
    const currentUser = firebaseAuth.currentUser;
    if (!handleAuthData(currentUser)) {
      // If no user found, try cookie
      const cookies = document.cookie.split(';');
      const authCookie = cookies.find(c => c.trim().startsWith('authUser='));
      if (authCookie) {
        try {
          const cookieData = JSON.parse(authCookie.split('=')[1]);
          setUserData(cookieData);
          dispatch(addUser(cookieData));
          localStorage.setItem("getUserData", JSON.stringify(cookieData));
        } catch (error) {
          console.error("Error parsing auth cookie:", error);
        }
      }
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [dispatch]);

  return userData;
};

export default useUserFromLocal;